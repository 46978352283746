<script>
    import Hero from "$lib/components/Hero.svelte";
    import ExperienceCard from "$lib/components/ExperienceCard.svelte";
</script>

<div class="p-10 space-y-4">
    <Hero/>
    <section class="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">

        <ExperienceCard title="Frontend" icon="ux-design.png">
            Mir macht es Freude, wenn der Benutzer Freude hat an einem guten, schnellen Frontend. Eines, das keine
            Erklärungen benötigt und stabil läuft.
        </ExperienceCard>

        <ExperienceCard title="Backend" icon="coding.png">
            Zuverlässigkeit und Skalierbarkeit sind wichtige Themenpunkte bei meiner Arbeit. Ich setze hierbei auf
            Backend-Services, On-Premise-Server und stabile Programmiersprachen und Setups.
        </ExperienceCard>

        <ExperienceCard title="Design" icon="art.png">
            Die Ästetik von Computerprogrammen beschäfigt mich schon seit mehr als 40 Jahren und ist auch einem stetigen
            Wandel und Fortschritt unterworfen.
        </ExperienceCard>
    </section>
</div>
