<div class="grid sm:grid-cols-3 gap-4 justify-items-center items-center">
    <div class="p-4 col-span-2">

        <h1 class="h1 text-6xl font-bold">Hallo, ich bin Lars!</h1>
        <p class="text-4xl">
            Ich verwandel Ideen in digitale Lösungen.
        </p>
    </div>
    <div class="p-4">
        <img alt="Lars Neujeffski" class="rounded-full" src="images/lars_neujeffski.jpg">
    </div>
</div>