<script>
    export let title;
    export let icon;
</script>

<div class="card variant-ringed-hollow p-4 md:p-8 space-y-4">
    <img class="w-auto max-w-full h-auto sm:w-1/5 md:w-1/4 lg:w-1/8 xl:w-1/5 dark:invert" src="/images/icons/{icon}" alt="Icon" >
    <h3 class="h3">{title}</h3>
    <p class="opacity-75">
        <slot/>
    </p>
</div>

